import { getExpertAvailability } from '@api/experts';
import WarningIcon from '@assets/images/icons/warning-icon.svg';
import NextIcon from '@assets/images/next-down.svg';
import PreviousIcon from '@assets/images/previous-up.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { smoothScrollTo } from '@helpers/general.helpers';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { ExpertInterface } from '@interfaces/index';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { formatDateToDateFullMonthYear } from '@utils/date.util';
import {
  AutocompleteGroupHeader,
  AutocompleteGroupItems,
  CustomAutocompletePopper,
  CustomPopper,
  FLIP_POPPER_MODS,
} from '@utils/mui.util';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import ConfirmBooking from '../ConfirmBooking';
import styles from './index.module.scss';

const MuiStyles = {
  autocomplete: {
    width: '100%',
    minWidth: '300px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '40px',
      height: '50px',
      border: '3px solid #019df2',
      '& .MuiInputBase-input': {
        color: '#019df2',
        fontWeight: 600,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#019df2',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#019df2',
      },
      '& .MuiAutocomplete-popupIndicator': {
        color: '#019df2',
      },
    },
    '@media (max-width: 600px)': {
      width: '100%',
      minWidth: '100%',
      '& .MuiAutocomplete-paper': {
        minWidth: '400px',
      },
    },
  },
};

interface TimeZoneOption {
  id: number;
  name: string;
  timeZone: string;
  group?: string;
}

type TimeZoneGroup = Record<string, TimeZoneOption[]>;

const ExpertBookingAvailability: React.FunctionComponent<ExpertBookingAvailabilityInterface> = ({
  expertDetails,
}) => {
  const slotsPerPage = 25;
  const timesPerPage = 25;
  const initialPageTimes = Math.floor((6 * 2) / timesPerPage);
  const [startDate, setStartDate] = useState(new Date());
  const [startTimeIndex, setStartTimeIndex] = useState(0);
  const [currentPageSlots, setCurrentPageSlots] = useState(0);
  const [currentPageDates, setCurrentPageDates] = useState(0);
  const [currentPageTimes, setCurrentPageTimes] = useState(initialPageTimes);

  const { isDesktop } = useWindowDimensions();
  const [availabilities, setAvailabilities] = useState<any[] | []>([]);

  const daysToShow = isDesktop ? 7 : 3;

  const [availabilityLoading, setAvailabilityLoading] = useState<boolean>(false);
  const [timeZones, setTimeZones] = useState<TimeZoneOption[] | []>([]);
  const [timeZonesLoading, setTimeZonesLoading] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState<TimeZoneOption>({
    id: -999,
    name: `Current Timezone - ${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentTimeZoneOffset = moment.tz(currentTimeZone).format('Z');
  const formattedCurrentTimeZoneOffset = `${currentTimeZoneOffset.replace(':', '.')}`;
  const [confirmBookingEnabled, setConfirmBookingEnabled] = useState<{
    enabled: boolean;
    date: string | null;
    time: string | null;
  }>({ enabled: false, date: null, time: null });

  const [currentStartTime, setCurrentStartTime] = useState(
    moment.tz(selectedTimeZone.timeZone).startOf('day').add(6, 'hours'),
  );
  const [currentEndTime, setCurrentEndTime] = useState(
    moment.tz(selectedTimeZone.timeZone).startOf('day').add(18, 'hours'),
  );

  const [availabilitySectionEnabled, setAvailabilitySectionEnabled] = useState<{
    available: boolean;
    date: string;
  }>({ available: true, date: '' });
  const [availabilitySectionErrorsOccurred, setAvailabilitySectionErrorsOccurred] = useState(false);

  useEffect(() => {
    getAvailability();
  }, [startDate, selectedTimeZone]);

  useEffect(() => {
    const allTimeZones = moment.tz.names();

    const groups: TimeZoneGroup = {
      Current: [],
      Europe: [],
      Australia: [],
      US: [],
      Asia: [],
    };

    const otherRegions: TimeZoneGroup = {};

    allTimeZones.forEach((tz, index) => {
      const offset = moment.tz(tz).format('Z').replace(':', '.');
      const option: TimeZoneOption = {
        id: index + 1,
        name: `${tz} (${offset} GMT)`,
        timeZone: tz,
      };

      if (tz.startsWith('Europe')) {
        groups.Europe.push(option);
      } else if (tz.startsWith('Australia')) {
        groups.Australia.push(option);
      } else if (tz.startsWith('America') || tz.startsWith('US')) {
        groups.US.push(option);
      } else if (tz.startsWith('Asia')) {
        groups.Asia.push(option);
      } else {
        const region = tz.split('/')[0];
        if (!otherRegions[region]) {
          otherRegions[region] = [];
        }

        otherRegions[region].push(option);
      }
    });

    const extractOffset = (name: string) => {
      const match = name.match(/\(([-+]\d+\.\d+) GMT\)/);

      return match ? parseFloat(match[1]) : 0;
    };

    Object.keys(groups).forEach((groupKey) => {
      groups[groupKey].sort((a, b) => extractOffset(a.name) - extractOffset(b.name));
    });

    Object.keys(otherRegions).forEach((regionKey) => {
      otherRegions[regionKey].sort((a, b) => extractOffset(a.name) - extractOffset(b.name));
    });

    const sortedOtherRegions = Object.keys(otherRegions)
      .sort()
      .reduce((acc, region) => ({ ...acc, [region]: otherRegions[region] }), {} as TimeZoneGroup);

    const groupedTimeZones: TimeZoneOption[] = [
      {
        id: -999,
        name: `Current Timezone - ${currentTimeZone} (${formattedCurrentTimeZoneOffset} GMT)`,
        timeZone: currentTimeZone,
      },
      { id: 0, name: 'Europe', timeZone: '', group: 'Europe' },
      ...groups.Europe,
      { id: 0, name: 'Australia', timeZone: '', group: 'Australia' },
      ...groups.Australia,
      { id: 0, name: 'US', timeZone: '', group: 'US' },
      ...groups.US,
      { id: 0, name: 'Asia', timeZone: '', group: 'Asia' },
      ...groups.Asia,
      ...Object.keys(sortedOtherRegions).flatMap((region) => [
        { id: 0, name: region, timeZone: '', group: region },
        ...sortedOtherRegions[region],
      ]),
    ];

    setTimeZones(groupedTimeZones);
    setTimeZonesLoading(false);
  }, []);

  const getAvailability = async () => {
    setAvailabilityLoading(true);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    const response = await getExpertAvailability(
      expertDetails?.id,
      startDate.toISOString().split('T')[0],
      endDate.toISOString().split('T')[0],
      selectedTimeZone.id === -999 || !selectedTimeZone
        ? currentTimeZone
        : selectedTimeZone.timeZone,
    );

    if ('errors' in response && Array.isArray(response?.errors) && response?.errors?.length > 0) {
      const message = response?.errors?.[0]?.message || '';
      if (message.includes('USER_CREDITS_NOT_AVAILABLE')) {
        const dateMatch = message.match(/USER_CREDITS_NOT_AVAILABLE,(\d{2}\/\d{2}\/\d{4})/);
        const extractedDate = dateMatch ? dateMatch[1] : null;
        setAvailabilitySectionEnabled({ available: false, date: extractedDate });
      } else {
        setAvailabilitySectionErrorsOccurred(true);
        setAvailabilitySectionEnabled({ available: true, date: '' });
      }
    } else {
      setAvailabilities(response.availabilities || []);
      setAvailabilityLoading(false);
    }
  };

  const generateTimes = (start: moment.MomentInput, end: moment.MomentInput) => {
    const times = [];
    const startMoment = moment(start);
    const endMoment = moment(end);

    while (startMoment.isSameOrBefore(endMoment)) {
      times.push(startMoment.format('HH:mm'));
      startMoment.add(30, 'minutes');
    }

    return times;
  };

  const times = generateTimes(currentStartTime, currentEndTime);

  const handleSlotClick = (slot: any) => {
    setConfirmBookingEnabled({
      enabled: true,
      date: slot.date,
      time: slot.time,
    });

    smoothScrollTo(isDesktop ? 1500 : 2300, 150);
  };

  const isTimeInRange = (time: string, date: string) => {
    const currentTime = moment.tz(`${date} ${time}`, 'YYYY-MM-DD HH:mm', selectedTimeZone.timeZone);

    return availabilities.some((slot) => {
      const slotStart = moment.tz(slot.startTime, selectedTimeZone.timeZone);
      const slotEnd = moment.tz(slot.endTime, selectedTimeZone.timeZone);

      return (
        currentTime.isSameOrAfter(slotStart) &&
        currentTime.isSameOrBefore(slotEnd) &&
        currentTime.isSame(slotStart, 'day')
      );
    });
  };

  const isValidDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);

    return !Number.isNaN(date.getTime());
  };

  const renderSlots = (date: string) => {
    if (!isValidDate(date)) {
      return null;
    }

    return times
      .slice(currentPageTimes * timesPerPage, (currentPageTimes + 1) * timesPerPage)
      .map((time, index) => {
        const isAvailable = isTimeInRange(time, date);

        const slotClass = isAvailable ? `${styles.slot} ${styles.available}` : styles.slot;

        if (
          index >= currentPageSlots * slotsPerPage &&
          index < (currentPageSlots + 1) * slotsPerPage
        ) {
          return (
            <div
              key={`${date}-${time}`}
              className={slotClass}
              onClick={() => isAvailable && handleSlotClick({ date, time })}
            >
              &nbsp;
            </div>
          );
        }

        return null;
      });
  };

  const generateDates = (sdate: string | number | Date, days: number) => {
    const dates = [];
    for (let i = 0; i < days; i += 1) {
      const date = moment.tz(sdate, selectedTimeZone.timeZone).add(i, 'days').format('YYYY-MM-DD');
      dates.push(date);
    }

    return dates;
  };

  const dates = generateDates(startDate, daysToShow);

  const handleNextWeek = () => {
    setStartDate(
      moment
        .tz(startDate, selectedTimeZone.timeZone)
        .add(isDesktop ? 7 : 3, 'days')
        .toDate(),
    );
  };

  const handlePreviousWeek = () => {
    setStartDate(
      moment
        .tz(startDate, selectedTimeZone.timeZone)
        .subtract(isDesktop ? 7 : 3, 'days')
        .toDate(),
    );
  };

  const handleNextTimesPage = () => {
    // Move 12 hours forward (from 6:00 AM to 6:00 PM to the next day)
    const newStartTime = moment(currentStartTime).add(12, 'hours');
    const newEndTime = moment(currentEndTime).add(12, 'hours');

    setCurrentStartTime(newStartTime);
    setCurrentEndTime(newEndTime);
  };

  const handlePreviousTimesPage = () => {
    // Move 12 hours backward (from 6:00 PM to 6:00 AM of the previous day)
    const newStartTime = moment(currentStartTime).subtract(12, 'hours');
    const newEndTime = moment(currentEndTime).subtract(12, 'hours');

    setCurrentStartTime(newStartTime);
    setCurrentEndTime(newEndTime);
  };

  const handleChange = (event: any, newValue: any) => {
    setSelectedTimeZone(newValue || { id: -999, name: 'Current Timezone' });
  };

  const formatDateOfHeader = (dateString: string) => {
    const date = new Date(dateString);
    const options: any = { weekday: 'short', day: '2-digit', month: 'short' };
    const parts = date.toLocaleDateString('en-US', options).split(', ');

    return `${parts[0]} ${parts[1].split(' ')[1]} ${parts[1].split(' ')[0]}`;
  };

  return (
    <>
      <div className={styles.container}>
        <h4>AVAILABILITY & BOOKING</h4>

        {availabilitySectionEnabled.available && !availabilitySectionErrorsOccurred ? (
          <>
            {(() => {
              switch (confirmBookingEnabled.enabled) {
                case true:
                  return (
                    <ConfirmBooking
                      handleBookingEnabling={setConfirmBookingEnabled}
                      bookingData={confirmBookingEnabled}
                      timezoneDetails={selectedTimeZone}
                      expertDetails={expertDetails}
                      handleFetchAvailability={getAvailability}
                    />
                  );
                default:
                  return (
                    <>
                      <div className={styles.topSubSection}>
                        <p>Select your desired session date and time to book this expert.</p>

                        <div className={styles.legend}>
                          <div className={styles.notAvailable}>not available</div>
                          <div className={styles.available}>available</div>
                        </div>
                      </div>

                      <div className={styles.bookingCalendar}>
                        <div className={styles.header}>
                          <DefaultButton
                            className={styles.hsSecondaryBtn}
                            onClick={handlePreviousWeek}
                          >
                            Previous week
                          </DefaultButton>

                          <div className={styles.headerPrevious} onClick={handlePreviousWeek}>
                            <Img src={NextIcon} alt="previous" />
                          </div>
                          <div className={styles.timezone}>
                            <Autocomplete
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              loading={timeZonesLoading}
                              groupBy={(option) => option.group || ''}
                              renderGroup={(params) => {
                                const isFirstGroup = Number(params.key) === 0;

                                return (
                                  <li key={params.key}>
                                    <AutocompleteGroupHeader
                                      style={{
                                        padding: isFirstGroup ? '0px' : '4px 10px',
                                      }}
                                    >
                                      {params.group}
                                    </AutocompleteGroupHeader>
                                    {params.group.length === 0 && (
                                      <AutocompleteGroupItems>
                                        {params.children}
                                      </AutocompleteGroupItems>
                                    )}
                                  </li>
                                );
                              }}
                              onOpen={() => setTimeZonesLoading(true)}
                              disableClearable
                              options={timeZones}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => (
                                <TextField {...params} label="" sx={MuiStyles.autocomplete} />
                              )}
                              value={selectedTimeZone}
                              onChange={handleChange}
                              sx={MuiStyles.autocomplete}
                              PopperComponent={isDesktop ? CustomPopper : CustomAutocompletePopper}
                              className={styles.autoComplete}
                              disablePortal
                              componentsProps={FLIP_POPPER_MODS}
                            />
                          </div>
                          <div className={styles.headerNext} onClick={handleNextWeek}>
                            <Img src={PreviousIcon} alt="next" />
                          </div>
                          <DefaultButton className={styles.hsSecondaryBtn} onClick={handleNextWeek}>
                            Next week
                          </DefaultButton>
                        </div>
                        <div className={styles.calendar}>
                          <div onClick={handlePreviousTimesPage} className={styles.scrollButton}>
                            <Img
                              src={NextIcon}
                              alt="Previous"
                              className={styles.timePaginationIcon}
                            />
                          </div>

                          <div className={styles.times}>
                            {times
                              .slice(
                                currentPageTimes * timesPerPage,
                                (currentPageTimes + 1) * timesPerPage,
                              )
                              .map((time) => (
                                <div key={time} className={styles.time}>
                                  {time}
                                </div>
                              ))}
                            <div onClick={handleNextTimesPage} className={styles.scrollButton}>
                              <Img
                                src={PreviousIcon}
                                alt="Next"
                                className={styles.timePaginationIconNext}
                              />
                            </div>
                          </div>

                          <div className={styles.dates}>
                            {dates
                              .slice(
                                currentPageDates * daysToShow,
                                (currentPageDates + 1) * daysToShow,
                              )
                              .map((date) => (
                                <div key={date} className={styles.date}>
                                  {formatDateOfHeader(date)}
                                </div>
                              ))}
                          </div>

                          {availabilityLoading ? (
                            <div className={styles.slotsLoading}>
                              {dates.map((dt, index) => (
                                <div className={styles.dayLoading} key={index}>
                                  {Array.from({
                                    length: times.slice(
                                      currentPageTimes * timesPerPage,
                                      (currentPageTimes + 1) * timesPerPage,
                                    ).length,
                                  }).map((_, idx) => (
                                    <Skeleton
                                      className={styles.slotLoading}
                                      variant="rectangular"
                                      key={idx}
                                    />
                                  ))}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className={styles.slots}>
                              {dates.map((date) => (
                                <div key={date} className={styles.day}>
                                  {renderSlots(date)}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  );
              }
            })()}
          </>
        ) : (
          <>
            {availabilitySectionErrorsOccurred ? (
              <div className={styles.creditsExpiredSection}>
                <p>Select your desired session date and time to book this expert.</p>
                <div className={styles.creditsExpiredSectionContent}>
                  <Img src={WarningIcon} alt="Warning icon" className={styles.warningIcon} />
                  <div className={styles.content}>
                    <p>Expert booking is currently not available.</p>
                    <p>If you need to speak to an expert, please contact your organisation.</p>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.creditsExpiredSection}>
                  <p>Select your desired session date and time to book this expert.</p>
                  <div className={styles.creditsExpiredSectionContent}>
                    <Img src={WarningIcon} alt="Warning icon" className={styles.warningIcon} />
                    <div className={styles.content}>
                      <p>You have used all of your expert credit allowance.</p>
                      <p>
                        You can book an expert session from{' '}
                        {formatDateToDateFullMonthYear(availabilitySectionEnabled.date)}.
                      </p>
                      <p>
                        If you need to speak to an expert before then, please contact your
                        organisation.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

interface ExpertBookingAvailabilityInterface
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  expertDetails: ExpertInterface;
}

export default ExpertBookingAvailability;
